<template>
    
    <v-row dense>

        <!--Обертка для секции-->
        <v-col cols="12" sm="12" md="6" class="section-wrapper">
            
            <!--Блок Лист контроля-->
            <v-card flat>
                <v-card-text>
                    <div class="form-box-title">{{$t("Лист_контроля")}}</div>

                    <!--Поле Срок исполнения-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Срок_исполнения")}}
                                    <span class="label-index-part">18</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.ControlDate ? $moment(document.Card.ControlDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Срок продления-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Срок_продления")}}
                                    <span class="label-index-part">29</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.ProlongationDate ? $moment(document.Card.ProlongationDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Фактический срок исполнения-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">{{ $t("Фактический_срок_исполнения") }}</label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                {{ document.Card.ExecDate ? $moment(document.Card.ExecDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                            </div>
                        </v-col>
                    </v-row>

                    <!--поле Ответственный исполнитель-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label label-with-index">
                                <div>
                                    {{$t("Ответственный_исполнитель")}}
                                    <span class="label-index-part">19</span>
                                </div>
                            </label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="icon-click-row-group icrg-align-center">
                                <div v-if="!isViewMode" @click="onExecuterSelect">
                                    <v-icon small v-tooltip.left-center="selectExecuterTooltip">
                                        fas fa-edit
                                    </v-icon>
                                </div>
                                <div class="onlyReadData">
                                    <v-workplace-chip 
                                        v-if="document.Card.ExecuterName" 
                                        :id="document.Card.ExecuterWorkplaceId" 
                                        :name="document.Card.ExecuterName" 
                                        :main="true"
                                        :canDelete="!isViewMode"
                                        :delFunc="onRemoveExecuter"
                                    />                                    
                                    
                                    <div class="chipAreaNoData d-flex align-center pl-1" v-if="!document.Card.ExecuterName && !isViewMode">
                                        <v-select-members-chip                                            
                                            target-type="Member"
                                            @on-select="onSelectExecuterChip"
                                            :items-params="membersExecuterSelectParams"
                                            :required="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <!--Поле Ответственный внутренний исполнитель-->
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{$t("Ответственный_внутренний_исполнитель")}}</label>
                        </v-col>
                        <v-col  cols="12" sm="12" md="8">
                            <div class="onlyReadData">
                                <v-workplace-chip
                                    v-if="document.Card.InnerExecuterId !== '00000000-0000-0000-0000-000000000000'"
                                    :id="document.Card.InnerExecuterId"
                                    :name="document.Card.InnerExecuterName"                                    
                                />
                                <template v-else>
                                    {{ $t("Не_указано") }}
                                </template>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>

        </v-col>

    </v-row>
    
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "EuolControl",
    computed: {
        ...mapGetters('actionsource', { dataSource: 'getDataSource' }),
        document() {
            return this.dataSource.Data.Object.Document;
        },
        isViewMode() {
            return this.dataSource.Data.FormId === "0901021";
        },
        selectExecuterTooltip(){
            return this.$t("Выбрать_ответственного_исполнителя");
        },
        membersExecuterSelectParams() {
            return { 
                includeInner: true,
                multiple: false,
                selected: [ this.document.Card.ExecuterWorkplaceId ]
            }
        }
    },
    methods: {
        ...mapActions('actionsource', { update: 'updateDataSource' }),
        async onExecuterSelect() {
            try
            {
                let selectMemberParams =  {
                    title: "Выбор_ответственного_исполнителя",
                    includeInner: true,
                    multiple: false,
                    selected: [ this.document.Card.ExecuterWorkplaceId ]
                }

                let { workplaceId, employeeId, name } = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                
                this.update({ property: 'Data.Object.Document.Card.ExecuterName', value: name });
                this.update({ property: 'Data.Object.Document.Card.ExecuterWorkplaceId', value: workplaceId });
                this.update({ property: 'Data.Object.Document.Card.ExecuterEmployeeId', value: employeeId });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        onSelectExecuterChip(e) {
            console.log(e);

            this.update({ property: 'Data.Object.Document.Card.ExecuterName', value: e.value.name });
            this.update({ property: 'Data.Object.Document.Card.ExecuterWorkplaceId', value: e.value.workplaceId });
            this.update({ property: 'Data.Object.Document.Card.ExecuterEmployeeId', value: e.value.employeeId });
        },
        onRemoveExecuter() {
            this.update({ property: 'Data.Object.Document.Card.ExecuterName', value: null });
            this.update({ property: 'Data.Object.Document.Card.ExecuterWorkplaceId', value: '00000000-0000-0000-0000-000000000000' });
            this.update({ property: 'Data.Object.Document.Card.ExecuterEmployeeId', value: '00000000-0000-0000-0000-000000000000' });
        }
    }
}
</script>